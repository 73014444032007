import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../../public/locales/en/translation.json';
import idTranslation from '../../public/locales/id/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      id: { translation: idTranslation },
    },
    localeDetection: true,
    lng: 'id', // Tentukan bahasa dari URL saat inisialisasi
    fallbackLng: 'id', // fallback ke bahasa default
    interpolation: {
      escapeValue: false, // react sudah aman dari XSS
    },
  });

export default i18n;
